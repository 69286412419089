import React from 'react';
import { LoginForm } from '@decub8/ui';

import { Content, Meta } from '@src/components';
import { useLogin } from '@src/hooks';
import { handleKeyDown } from '@src/utils/form';

const Login: React.FC = () => {
    const props = useLogin();
    const error_message = Object.values(props.errors)[0];

    const renderBanMessage = () => (
        <div className="text-accent text-lg text-center">
            Your account is currently blocked from signing in. Please contact
            Support{' '}
            <a
                className="underline"
                href="mailto:compliance@decubate.com"
                target="_blank"
                rel="noopener noreferrer"
            >
                here
            </a>
            .{' '}
        </div>
    );
    return (
        <div
            className="focus:outline-none cursor-default"
            onKeyDown={(e) =>
                !props.disabled && handleKeyDown(e, 'Enter', props.handleClick)
            }
            tabIndex={0}
        >
            <Content>
                <Meta title="Sign in" />
                <div className="min-h-screen flex items-center justify-center pb-[120px]">
                    {error_message ===
                    'Banned account, please contact support' ? (
                        renderBanMessage()
                    ) : (
                        <LoginForm {...props} />
                    )}
                </div>
            </Content>
        </div>
    );
};

export default Login;
